<template>
  <div>
    <MyHeader :title="headtitle" :ShowBack="true" />
    <van-cell-group :title="$t('lang.descriptions')" v-if="isMiss">
      <van-field
        v-model="memo"
        :readonly="!showSubmit"
        rows="1"
        autosize
        type="textarea"
        :placeholder="$t('lang.descriptions_input')"
      />
    </van-cell-group>
    <div style="text-align: right; padding-right: 10px" v-if="isMiss">
      <van-button
        type="info"
        size="small"
        v-show="showSubmit"
        @click="onSubmit"
      >
        {{ $t("lang.bt_submit") }}
      </van-button>
      <van-button
        type="primary"
        size="small"
        v-show="showAudit"
        style="margin: 5px 10px"
        @click="onAudit"
      >
        {{ $t("lang.bt_audit") }}
      </van-button>
    </div>
    <van-cell-group :title="$t('lang.event')">
      <van-cell
        v-for="mData in lstData"
        :key="mData.id"
        :title="mData.eventName"
        :icon="mData.isNormal ? 'checked' : 'warning'"
        @click="
          onShowDetail(
            mData.type,
            mData.isNormal,
            mData.repairId,
            mData.type == 3 || mData.type == 4 || mData.type == 5
              ? mData.logicName
              : ''
          )
        "
      >
        <template #right-icon>
          <van-tag
            :plain="mData.isNormal"
            :type="mData.isNormal ? 'success' : 'danger'"
            size="medium"
            class="custtag"
            >{{
              mData.type == 0
                ? mData.numValue +
                  mData.unitName +
                  "(" +
                  mData.minValue +
                  "-" +
                  mData.maxValue +
                  ")"
                : mData.type == 3 || mData.type == 4 || mData.type == 5
                ? $t("lang.preview")
                : mData.logicName
            }}</van-tag
          >
        </template>
      </van-cell>
      <div
        v-show="showHint"
        style="margin: 20px; text-align: center; display: block; color: #969799"
      >
        {{ $t("lang.nodata") }}
      </div>
    </van-cell-group>

    <van-dialog
      v-model="showVideo"
      show-cancel-button
      :show-confirm-button="false"
      :cancel-button-text="$t('lang.close')"
      @close="onClose()"
    >
      <video-player
        class="video-player-box vjs-big-play-centered"
        ref="videoPlayer"
        :options="playerOptions"
        :playsinline="true"
        customEventName="customstatechangedeventname"
      >
      </video-player>
    </van-dialog>
  </div>
</template>
<style scoped>
.van-icon-checked {
  color: green;
}
.van-icon-warning {
  color: red;
}
.custtag {
  height: 24px;
}
</style>
<script>
import "video.js/dist/video-js.css";
import { videoPlayer } from "vue-video-player";
import { ImagePreview } from "vant";
import MyHeader from "@/components/Header.vue";
import {
  GetPollingPlanDetail,
  SaveComment,
  AuditComment,
  GetServerUrl,
} from "@/api/index.js";

export default {
  components: { MyHeader, videoPlayer },
  data() {
    return {
      detailid: 0,
      lstData: [],
      headtitle: "",
      isMiss: false,
      memo: "",
      isaudit: false,

      showSubmit: false,
      showAudit: false,

      showHint: true,
      ServerUrl: "",

      showVideo: false,
      playerOptions: {
        muted: false,
        language: "zh-CN",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        fluid: true,
        sources: [
          {
            type: "video/mp4",
            src: "",
          },
        ],
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true, // 是否显示全屏按钮
        },
      },
    };
  },

  activated() {
    if (this.$route.params.id) {
      let mPer = this.$store.getters.getPermission;
      this.detailid = this.$route.params.id;
      this.headtitle = this.$route.params.title;
      this.isMiss = this.$route.params.isMiss;
      this.memo = this.$route.params.memo;
      this.isaudit = this.$route.params.isaudit;

      this.showSubmit =
        mPer == undefined || this.isaudit ? false : mPer.isRemark;
      this.showAudit = mPer == undefined || this.isaudit ? false : mPer.isAudit;

      if (this.detailid) this.Query(this.detailid);
    }
  },

  methods: {
    Query(id) {
      let that = this;
      GetPollingPlanDetail(
        {
          token: this.$store.getters.getToken,
          patrolPlanRecordId: id,
        },
        (ret) => {
          if (ret) {
            if (ret.code == 0) {
              this.lstData = ret.data;
              this.showHint = that.lstData.length == 0;
            } else this.$Toast(ret.message);
          } else this.$Toast(this.$t("lang.queryfail"));
        }
      );
    },

    onSubmit() {
      console.log(this.memo);
      if (this.memo == undefined || this.memo == "") {
        this.$Toast(this.$t("lang.descriptions_input"));
      } else {
        SaveComment(
          {
            token: this.$store.getters.getToken,
            patrolPlanRecordId: this.detailid,
            comment: this.memo.trim(),
          },
          (ret) => {
            if (ret && ret.code == 0) {
              this.showSubmit = false;
              this.showAudit = false;
              this.$Toast(this.$t("lang.hint_submit_success"));
            } else
              this.$Toast(
                !ret ? this.$t("lang.hint_submit_fail") : ret.message
              );
          }
        );
      }
    },

    onClose() {
      this.$refs.videoPlayer.player.pause();
    },

    onAudit() {
      AuditComment(
        {
          token: this.$store.getters.getToken,
          patrolPlanRecordId: this.detailid,
        },
        (ret) => {
          if (ret && ret.code == 0)
            this.$Toast(this.$t("lang.hint_audit_success"));
          else
            this.$Toast(!ret ? this.$t("lang.hint_audit_fail") : ret.message);
        }
      );
    },

    onShowDetail(eventtype, isNormal, repairId, eventvalue) {
      if (eventtype == 3) {
        //图片
        if (eventvalue == "") {
          this.$Toast(this.$t("lang.hint_noattachment"));
        } else {
          this.ServerUrl = GetServerUrl();
          let imgurl = eventvalue.replace(
            RegExp("/app/images", "g"),
            this.ServerUrl + "app/images"
          );
          let mImage1 = imgurl.replace(RegExp("preview/", "g"), "").split("|");
          ImagePreview({
            images: mImage1,
            startPosition: 0,
          });
        }
      } else if (eventtype == 4 || eventtype == 5) {
        //录音,视频
        if (eventvalue == "") {
          this.$Toast(this.$t("lang.hint_noattachment"));
        } else {
          this.ServerUrl = GetServerUrl();
          this.playerOptions.sources[0].src = this.ServerUrl + eventvalue;
          this.showVideo = true;
        }
      } else {
        if (isNormal) return;
        if (repairId == 0) {
          this.$Toast(this.$t("lang.hint_noattachment"));
          return;
        }
        this.$router.push({
          name: "QueryDetail",
          query: {
            id: repairId,
          },
        });
      }
    },
  },
};
</script>
